import React, { Component } from 'react' // Import
import Container from 'react-bootstrap/Container'
import Header, { Item, Float, Foot, Slider } from '../components/main'
import { Helm } from '../components/header'
import { Toast, Row, Col, Alert } from 'react-bootstrap'
import { cap,pw } from '../params'
import '../style/style.css'
import '../style/sty.scss'
import music from '../assets/music/ghea.aac'
import mask from '../assets/img/dinny/mask.svg'
import distance from '../assets/img/dinny/distance.svg'
import salaman from '../assets/img/dinny/salaman.svg'
import logoig from '../assets/img/dinny/logoig.svg'
import burung from '../assets/img/ghea/burung.svg'
import bunga6 from '../assets/img/bunga6.png'
import AOS from 'aos';
import { gambar } from '../params'
import post from '../params/post'

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import logo from '../assets/img/logo.ico'
import "aos/dist/aos.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import { CopyToClipboard } from 'react-copy-to-clipboard';

let cmain = 'rgb(209,183,113)'
let mainfont = 'rgb(209,183,113)'
let black = 'rgb(38,38,38)'
export default class Halo extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef()
    this.myRef = React.createRef()
    this.nama = React.createRef()
    this.alamat = React.createRef()
    this.pesan = React.createRef()
    this.state = {
      acara: [],
      days: '00',
      minutes: '00',
      hours: '00',
      seconds: '00',
      hide: true,
      hadir: true,
      err: [],
      submitted: '',
      sesi: 2,
      showgift: false,
      showgift1:false
    }
  }
  componentDidMount() {
    AOS.init({
      // initialise with other settings
      duration: 2000
    });
    var countDownDate = new Date("12/20/2020").getTime();
    // Update the count down every 1 second
    var x = setInterval(() => {
      // Get today's date and time
      var now = new Date().getTime();
      // Find the distance between now and the count down date
      var distance = countDownDate - now;
      // Time calculations for days, hours, minutes and seconds
      var days = Math.floor(distance / (1000 * 60 * 60 * 24));
      var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((distance % (1000 * 60)) / 1000);
      this.setState({
        days: days, hours: hours, minutes: minutes, seconds: seconds
      })
    }, 1000);
  }

  play = () => {
    AOS.refresh()
    var snd = new Audio(music);
    snd.type = 'audio/aac';
    snd.play();
    this.setState({ hide: false })
    setTimeout(() => {
      var elmnt = document.getElementById('top');
      elmnt.scrollIntoView();
    }, 1000)
  }

  useQuery = () => {
    return new URLSearchParams(this.props.location.search);
  }
  handleSubmit = async () => {
    let err = []
    let local = localStorage.getItem('pesan')
    if (this.nama.current.value == "") {
      err.push('Nama tidak Boleh Kosong')
    }
    if (this.pesan.current.value == "") {
      err.push('Pesan tidak Boleh Kosong')
    }
    if (this.alamat.current.value == "") {
      err.push('Alamat tidak Boleh Kosong')
    }
    if (err.length == 0) {
      confirmAlert({
        message: local ? `Kami mendeteksi bahwa anda telah mengirimkan pesan \" ${local} \", apakah anda ingin mengirim pesan lagi?` : 'Yakin untuk Mengirim Pesan?',
        buttons: [
          {
            label: 'Yes',
            onClick: async () => {
              let send = await post(
                ` dari: "${this.nama.current.value}", hadir: "${this.state.hadir}", jumlahhadir: "", kepada: "ghea-jodi", pesan:"${this.pesan.current.value}",alamat: ""`
              )
            if (send.status == 200) {
                this.setState({ submitted: true })
                localStorage.setItem('pesan', this.pesan.current.value)
                this.nama.current.value = ''
                this.pesan.current.value = ''
            }else{
                err.push('Koneksi Gagal')
            }
            }
          },
          {
            label: 'No',
            onClick: () => { }
          }
        ]
      });
    } else {
      this.setState({ err: err, submitted: false })
    }


  }
  render() {
    let { hadir, days, hours, minutes, seconds, hide, submitted, err, showgift,showgift1 } = this.state
    let slide = ["	https://i.ibb.co/ggYdzXK/DSC-6830.jpg	",
      "	https://i.ibb.co/k1h8YX7/DSC-6835.jpg	",
      "	https://i.ibb.co/2FXPS8W/DSC-6879.jpg	",
      "	https://i.ibb.co/PNpyF1M/DSC-6888.jpg	",
      "	https://i.ibb.co/G2VK7wy/DSC-6897.jpg	",
      "	https://i.ibb.co/KhrrjsC/DSC-6909.jpg	",
      "	https://i.ibb.co/ZJYt0Np/DSC-6914.jpg	",
      "	https://i.ibb.co/vjTwhH1/DSC-6928.jpg	",
      "	https://i.ibb.co/khLHZqS/DSC-6957.jpg	",
      "	https://i.ibb.co/dLJ275s/DSC-6972.jpg	",
      "	https://i.ibb.co/HPs6vjR/DSC-6980.jpg	",
    ]
    let slider = []
    slide.map(v => {
      slider.push(gambar(v, 95, 'auto&func=fit&bg_img_fit=1&bg_opacity=0.75&w=720&h=520'))
    })
    let query = this.useQuery().get('name');
    query = query ? cap(query) : ''

    return (
      <>
        <Helm
          title='Undanganku - Ghea & Jodi'
          desc="undangan digital berbasis website untuk berbagai kebutuhan acara"
          logo={logo}
          img={'https://i.ibb.co/ggYdzXK/DSC-6830.jpg'}
          url='https://undanganku.me/ghea-jodi'
        />

        <div id='gold5' style={{
          backgroundColor: '#171717',
        }}>
          {
            this.useQuery().get('x')=="x"?(<Float/>):false
          }
          <div className='w-100' style={{
            overflow: 'hidden', maxWidth: '100vw',

          }}>
            <Container fluid id='g3-header' className='position-relative' style={{
              backgroundImage: `url('${gambar(pw("ghea-jodi","modal.jpg"),95,'auto&func=fit&bg_img_fit=1&bg_opacity=0.9&w=1440&h=720')}')`,
              backgroundColor: 'white'
            }}>
            
              <div className="position-absolute w-100 text-center" style={{bottom:'4%',left:0}}>
              <Item>
                {
                  <h2 className={`col-md-4 roboto-slab text-center pt-3 pt-md-3`} style={{ marginTop: '0', color: 'white' }}>
                    Kepada Yth :<br /> {query ? query : ''} <br /></h2>
                }</Item>
              <Row className='justify-content-center'>
                <div onClick={() => { this.play() }}
                  className={`col-md-4 button btn roboto-slab text-center ${hide ? 'show' : 'hide'}`}
                  style={{ marginTop: 0, color: 'white', borderColor: 'white' }}>
                  Open Invitation
                </div>
              </Row>
              </div>
            </Container>

            <div className={hide ? 'd-none' : 'd-block'}>
              <div id="top" style={{ backgroundColor: 'transparent' }}>
                <Container id='sectiongold57'>
                  <div className='py-3'>

                    <Item>
                      <div data-aos="fade-left" data-aos-duration="1000"
                        className='col-10 col-lg-8 kotak' style={{ backgroundColor: 'white' }}>
                        <Item>
                          <div className='item'>
                            <Item>
                              <div style={{ color: black }}>
                                {days}
                              </div>
                            </Item>
                            <Item>
                              <span style={{ color: black }}>
                                Days
                                </span>
                            </Item>
                          </div>
                          <div className='dot' style={{ color: black }}>:</div>
                          <div className='item' >
                            <Item>
                              <div style={{ color: black }}>
                                {hours}
                              </div>
                            </Item>
                            <Item>
                              <span style={{ color: black }}>
                                Hours
                      </span>
                            </Item>
                          </div>
                          <div className='dot' style={{ color: black }}>:</div>
                          <div className='item' >
                            <Item>
                              <div style={{ color: black }}>
                                {minutes}
                              </div>
                            </Item>
                            <Item>
                              <span style={{ color: black }}>
                                Mins
                      </span>
                            </Item>
                          </div>
                          <div className='dot' style={{ color: black }}>:</div>
                          <div className='item'>
                            <Item>
                              <div style={{ color: black }}>
                                {seconds}
                              </div>
                            </Item>
                            <Item>
                              <span style={{ color: black }}>
                                Secs
                      </span>
                            </Item>
                          </div>
                        </Item>

                      </div>
                    </Item>
                  </div></Container>

                <Container className="dinny px-3 py-3">
                  <Item>
                    <Col xs={6} md={2}>
                      <img src={burung} data-aos="zoom-in" data-aos-duration="1000" className='img-fluid w-100' />
                    </Col>
                  </Item>
                  <Item>
                    <p className="fs24 text-center px-3">
                      <span className="fs16" style={{ color: "white" }}>
                        And one of His signs is that He created for you spouses
                        from among yourselves so that you may find comfort in them.
                        And He has placed between you compassion and mercy.
                            Surely in this are signs for people who reflect. <br /><br />(Ar-Rum :21)
                        </span>
                    </p>
                  </Item>
                  <Item>
                    <div className="w-100">
                      <img className="w-100 img-fluid"
                        src={gambar("https://i.ibb.co/ZJYt0Np/DSC-6914.jpg", 95, 'auto&func=fit&bg_img_fit=1&bg_opacity=0.75&w=720&h=520')} />
                    </div>
                  </Item>
                </Container>
                <Container className="text-center dinny my-3 px-3">
                  <Item>
                    <Col className="py-3 px-md-5" style={{ border: `2px solid ${cmain}`, borderRadius: '10px' }}>
                      <h1 className="fs24 tinos"
                        style={{
                          color: mainfont,
                        }}>
                        LOVE IN THE TIME OF COVID-19
                          </h1>
                      <Item>
                        <Col xs={12}>
                          <p className="text-justify w-100 fs16" style={{ color: mainfont }}>
                            Dear friends and families, due to current circumstances, 
                          <b> we aren’t able to invite you to our Wedding Party
                          </b>. It will be an intimate ceremony and we will share with only one another. Please know you will always be in our thought, and forever will be in our hearts.
                          </p>
                        </Col>
                        
                      </Item>

                    </Col>
                  </Item>
                  <Item>
                    <div className="w-100 py-4">
                      <img className="w-100 img-fluid"
                        src={gambar("https://i.ibb.co/2FXPS8W/DSC-6879.jpg", 95, 'auto&func=fit&bg_img_fit=1&bg_opacity=0.75&w=720&h=520')} />
                    </div>
                  </Item>
                </Container>


                <Container fluid id='name-ghea' className="py-5 dinny tinos px-0" >
                  <h1 >
                  Ghea Rizky Olvia, S.H.
                  </h1>
                  <p className="w-100 text-center" style={{transform:'scale(0.8)'}}>
                    daughter of<br/> (Alm.) Mr. Edi Sofhian <br/>& <br/>Mrs. Titi Janiah
                  </p>
                  <h1 style={{transform:'scale(1.0)'}}>
                    &
                  </h1>
                  <h1 classNam="py-3">
                  dr. Jodi Nugraha, S.Ked.
                  </h1>
                  <p  className="w-100 text-center"  style={{transform:'scale(0.8)'}}>
                    son of <br/>(Alm.) Mr. Kurnia Nandika <br/> &<br/> Mrs. Lilis Juariah
                  </p>

                  <h2 className="pt-3" style={{color:cmain}}>
                    <b>
                      Sunday, December 20th , 2020
                    <br />
                    Bintang Sintuk Hotel
                    </b>
                  </h2>
                  <Item>
<div style={{border:`2px solid ${cmain}`,borderRadius:'10px'}} className="p-3 mt-5 mx-3">
<Item>
<p style={{color:cmain,fontSize:'16px'}} className="w-100 text-center">come and join us celebrating our special day</p>

  </Item><Item>
<img src={pw("asset","logoig-cream.svg")} className='btn p-0'
    onClick={() => { window.open(`https://instagram.com/ghea_jodi`) }} width="35px" height="35px" />
</Item>
</div>

</Item>
                </Container>



                <Container id='sectiongold58' >

                  <div className='pt-3 mb-lg-3'>
                    {/* <Item>
                      <Col xs={4} lg={2}>
                        <img data-aos="zoom-in" data-aos-duration="1000" src={bunga6} className='img-fluid w-100' />
                      </Col>
                    </Item> */}
                    <Item>
                      <div className='col-10 col-lg-6 kotak pb-4 pt-4' data-aos="left-left" data-aos-duration="1000">
                        <Item>
                          <div id="name-ghea">
                            <h1 style={{
                              fontFamily: '"Marck Script", cursive',
                              color: black
                            }}>
                              Wedding Wish
                          </h1>
                          </div>
                        </Item>
                        <Item>
                          <form className="col-12 w-100" id="name-ghea">
                            <input ref={this.nama} type='text' className="col-12 w-100 text-center" placeholder="Name" name='nama' defaultValue={query ? query : ''} />
                            <input ref={this.alamat} type='text-area' className="col-12 w-100 text-center bigger" placeholder="Your Address or @Your Social Media" name='alamat' />

                            <input ref={this.pesan} type='text-area' className="col-12 w-100 text-center bigger" placeholder="Message" name='pesan' />
                            {/* <Item>
                              <div id="formradio">
                                <div class="custom_radio row justify-content-center">
                                  <div onClick={() => {
                                    this.setState({ hadir: true })
                                  }
                                  }>
                                    <input type="radio" id="featured-1" name="featured" checked={hadir ? true : false} />
                                    <label for="featured-1" style={{ color: mainfont }}>Hadir</label>
                                  </div>
                                  <div onClick={() => {
                                    this.setState({ hadir: false })
                                  }
                                  } className="pl-5">
                                    <input type="radio" id="featured-2" name="featured" checked={hadir ? false : true} />
                                    <label for="featured-2"
                                      style={{ color: mainfont }}
                                    >Tidak Hadir</label>
                                  </div>

                                </div>
                              </div>
                            </Item> */}

                            <Item>
                              <Col xs={12} className=''>
                                {
                                  submitted == true ? (
                                    <Alert variant='success' style={{ fontSize: '16px' }}>
                                      Pesan anda sudah disampaikan
                                    </Alert>) : (submitted === false ? (
                                      <Alert variant='danger' style={{ fontSize: '16px' }}>
                                        {
                                          err.map(val => {
                                            return (
                                              <li>{val}</li>
                                            )
                                          })
                                        }

                                      </Alert>
                                    ) : false)
                                }

                              </Col>
                            </Item>
                            <Item>
                              <div className='col-6 button rounded btn'
                                onClick={() => this.handleSubmit()} style={{ backgroundColor: cmain }} no> Submit </div>
                            </Item>
                          </form>
                        </Item>
                      </div>
                    </Item>
                  </div>
                </Container>


                <Container className="py-3">
                  <p className='w-100 text-center' style={{ color: "white", fontSize: '16px' }}>
                    Your prayer are present enough for us! But for those who are willing to give us something, please choose our gift registries below
                  </p>
                  <Item>
                    <Col
                      onClick={() => this.setState({ showgift: !showgift })}
                      xs={10} md={4}
                      style={{
                        border: `2px solid white`,
                        borderRadius: '10px'
                      }}
                      className="p-2 mx-md-2 mt-3 mt-md-0 btn">
                      <Item>
                        <img src="https://www.flaticon.com/svg/static/icons/svg/1139/1139982.svg" className="img-fluid"
                          style={{ width: "10%", height: "10%" }} />
                        <p className="mb-0 my-auto ml-3 fs16" style={{ color: 'white' }}>
                          <b>Send Gift (Click here)</b>
                        </p>
                      </Item>
                    </Col>
                  </Item>
                </Container>
                <Container className={`text-center mt-3 aulia-wedding py-3 ${!showgift ? 'trsnone' : 'trs'}`} >
                  <div id="name-ghea">
                    <Item>
                      <Col className="py-3 px-md-5 mx-2"
                        style={{
                          backgroundColor: '#171717',
                          borderRadius: '10px',
                          border: '2px solid white'
                        }}>
                        <h1>
                          Wedding Gift
                      </h1>
                        <Item>
                          <h2 className="text-center w-100 pt-3" style={{ color: "white" }}>
                            Address
                        </h2>
                          <p className="text-center w-100 px-3">
                            Jalan Gendang No. 74 RT 23 Tanjung Limau<br />
                          Bontang Utara 75311, Kota Bontang – Kaltim
                        </p>

                          <h2 className="text-center w-100 pt-3" style={{ color: "white" }}>
                            Cashless System
                        </h2>
                        </Item>
                        <Item>
                          <Col xs={8} md={4}>
                            <img src='https://i.ibb.co/j399Xng/Whats-App-Image-2020-11-19-at-10-22-32-AM-1.jpg' className='w-100 img-fluid' />
                          </Col>
                        </Item>
                        <Item>
                          <p className="text-center w-100">
                            OVO
                          </p>
                        </Item>
                        <Item>
                          <Col xs={8} md={4}>
                            <img src='https://i.ibb.co/4Ww2Gwg/Whats-App-Image-2020-11-19-at-10-22-32-AM.jpg' className='p-2 w-100 img-fluid' />
                          </Col>
                        </Item>

                        <p className="text-center w-100">
                          DANA
                          </p>



                        <Item>
                          <Col xs={8} md={4}>
                            <img src='https://i.ibb.co/4Ww2Gwg/Whats-App-Image-2020-11-19-at-10-22-32-AM.jpg' className='p-2 w-100 img-fluid' />
                          </Col>
                        </Item>
                        <Item>
                          <p className="text-center w-100">
                            Mandiri Syariah - 7146568143 <br />
                            (Ghea Rizky Olvia)
                          </p>
                          <Toast onClose={() => this.setState({ show: false })} show={this.state.show} delay={3000} autohide >
                            <Toast.Body>Copied!</Toast.Body>
                          </Toast>
                          <CopyToClipboard text="7146568143">
                            <Col xs={8} className='btn button mb-5 mt-1'
                              onClick={(e) => {
                                this.setState({ show: true })

                              }}>
                              Copy
                          </Col>
                          </CopyToClipboard>


                        </Item>
                        <Item>
                        <Col xs={8} className="btn" style={{backgroundColor:'white'}} onClick={()=>{this.setState({showgift1:!showgift1})}}>
                          <Item>
                          <Col xs={11}>
                          <h2 className="text-center mb-0" >
                          How to use QR Codes 
                          </h2>
                          </Col>
                          <Col xs={1}>
                            <Row className="h-100">
                          <img src="https://www.flaticon.com/svg/static/icons/svg/1076/1076745.svg" style={{width:'35px',height:'35px'}}/>
                            </Row>
                          </Col>
                          </Item>
                          </Col>
                        </Item>
                        <Item>
                          
                    
                                  <Item>
                    <p className={` px-3 pt-2 text-left ${!showgift1 ? 'd-none' : 'd-block '}`}>
                    Panduan Penggunaan Aplikasi OVO dan Dana :
                      <ol>
                        <li>
                        Buka aplikasi OVO / Dana lalu tekan pilihan 
                        <div className="d-inline d-md-block">
                        {` Scan/Pay pada tampilan di bagian bawah`}
                          </div>
                        </li>
                        <li>
                        Pindai barcode penerima 
                        </li>
                        <li>
                        Masukkan Nominal Transfer 
                        </li>
                        <li>
                        Pilih Lanjutkan   
                        </li>
                        <li>
                        Selesai
                        </li>
                      </ol>
                    </p>
                    </Item>
                                
                  </Item>
                      </Col>
                    </Item>

                  </div>

                  

                </Container>
                <Container className='mt-3 py-3' data-aos="fade-right" data-aos-duration="1000">
                  <Slider slide={slider} />
                </Container>
                <Container id='sectiongold56'>
                  <div className='pt-3'>

                    <div data-aos={`fade-right`} data-aos-duration="2000">
                      <Item>
                        <div className='kotak col-10' style={{ backgroundColor: cmain }}>
                          <Item>
                            <p className='text-center p-2 px-4'>
                              Whatever our souls are made of, his and mine are the same. <br />- Emily Brontë -
                              </p>
                          </Item>
                        </div>

                      </Item>
                    </div>
                  </div>
                </Container>


                <Foot ig={logoig}/>
              </div>
            </div>
          </div>
        </div>

      </>


    )
  }
}

